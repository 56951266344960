import React from "react";

import CharterHallLogo from "@images/charter_hall_logo.png";
import DexusLogo from "@images/dexus_logo.png";
import GptLogo from "@images/gpt_logo.png";
import LendLeaseLogo from "@images/lendlease_logo.png";
import MirvacLogo from "@images/mirvac_logo.png";
import VicinityLogo from "@images/vicinity_logo.png";
// import FujiFilmLogo from "@images/fujifilm_logo.png";
// import MyerLogo from "@images/myer_logo.png";

const LogoCloud = () => (
  <div className="bg-gray-100">
    <div className="max-w-7xl mx-auto py-8 md:py-12 px-4 sm:px-6 lg:px-8">
      <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
        WAIVPAY is trusted by Australia's leading shopping centres, brands and retailers
      </p>
      <div className="mt-6 grid grid-cols-2 gap-2 md:grid-cols-6">
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img className="h-24" src={MirvacLogo} alt="Mirvac Logo" />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img className="h-24" src={LendLeaseLogo} alt="Lend Lease Logo" />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img className="h-24 mt-2" src={GptLogo} alt="GPT Logo" />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img className="h-28" src={VicinityLogo} alt="Vicinity Logo" />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img className="h-8 mt-10" src={DexusLogo} alt="Dexus Logo" />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img className="h-14 mt-6" src={CharterHallLogo} alt="CharterHall Logo" />
        </div>
      </div>
    </div>
  </div>
)

export default LogoCloud;
